import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import MyLayout from "../components/layout"
import SEO from "../components/seo"
import {
    fontStyle,
    subheaderFontSize,
  } from "../components/statics"
import { Col, Row } from "antd"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;

  //the post.md file does have new line characters, but they are not displayed properly on site
  //tried to set div style='pre-line' (and others), to no avail.
  //instead just read in every newline and replace with proper html break tag
  let formattedHTML = ''; 

  //console.log('DEBUG HTML: ', JSON.stringify( html ));
  //console.log('DEBUG new img: ', JSON.stringify( frontmatter.bannerImage ));


  if ( html ){
     let find = `</p>\n<p>`;
     let re = new RegExp(find, 'g');
     formattedHTML = html.replace(re, '</p><br/><p>'); //definitely a hack..
  }

  return (
    <MyLayout>
    <SEO title={frontmatter.title} />


 {/*Similar to importing individual images: landingSteps.js > ScreenshotComponent() + iphoneImportWeb Query + <Img>*/}
    {frontmatter.bannerImage &&
    <Row
      span={15}
      style={{
        //backgroundColor: 'red',
        //paddingBottom: "4%",
        paddingTop: "4%",
      }}
    >
      <div style={{ width: undefined, maxHeight: 600 }}>

      <Img
      fluid={frontmatter.bannerImage.childImageSharp.fluid}
      style={{}}
      imgStyle={{ objectFit: "contain" }} //need or will not size correctly inside nested components I think
    />

       </div>
    </Row>
    }



 
     <Row
     type="flex" //important or justify does not work
     justify="center"
     style={{
         paddingLeft: "10vw", paddingRight: "10vw",
    //backgroundColor:'blue',
    alignContent:'center',
    alignItems:'center',
    flex:1,
    }}
     >


    


         <Col span={24}>
    <div className="blog-post-container">
      <div className="blog-post">
          <div style={{
               textAlign:"center",
               paddingTop:"5vh",
               margin:"auto",
               lineHeight:"51px",
               verticalAlign:"middle",
          }}> 
        <h1 style={{...fontStyle,...{paddingBottom:0, marginBottom:0,},}}>{frontmatter.title}</h1>
        <p 
        //style={{fontSize:13, color:'lightslategray',}}
        style={{
            ...smallPStyle,
            ...{
               display:'inline',
            },
          }}
        
        >{frontmatter.date} 
        
        {/*Row of data + time to read (if exists) */}
        {frontmatter.readMins && <p style={{
                ...smallPStyle,
                ...{
                   display:'inline',
                },
              }}>  • {frontmatter.readMins} min read</p>}
        {/*Uncomment below to debug ui with example data*/}
        {/*{true && <p 
 
             style={{
                ...smallPStyle,
                ...{
                   display:'inline',
                },
              }}
            >  • 5 min Read</p>} */}

        <p style={{fontSize:13, display:'block', color: smallTextColor, whiteSpace:'pre'}}>•       •       •</p>

        </p>              
        
  
        
        </div>

        

   

        { typeof formattedHTML &&
          <div
          className="blog-post-content"
          dangerouslySetInnerHTML={{ __html: formattedHTML }}
          
          //Thought text would change to specific font type, but did not, oddly..
          style={{...{fontStyle},...{textAlign:'left'}}}
           />
        }
 
  

      </div>
    </div>
    </Col>
    </Row>
    </MyLayout>


  )
}
//Gatsby magic takes the results of the pageQuery and injects it into Template, as a 'data' property
//
//Build error: Cannot query field "bannerImage" on type "MarkdownRemarkFrontmatter"
//         or: Field "bannerImage" must not have a selection since type "String" has no subfields.
//             This can happen if you e.g. accidentally added { } to the field "bannerImage". If you didn't expect "bannerImage" to be of type "String" make sure that your input source and/or plugin is correct.
//While this is vexing, here was the crux of the problem
//I had pre-existing posts *.md files, I simply wanted to add 'bannerImage' as a field > which is of type 'File'
//I also wanted to implement a way to add this field to NEW POSTS ONLY
//  -one work around would have been to put 'bannerImage' on Every post, and gatsby/graphql would have likely been happy
//  -I did not like this because, what if my website was old, I would not want to update 700 posts with a random new field..
//I tried to modify gatsby-node.js to tell it to default 'bannerImage' to type file > worked locally , but NOT remotely (Netlify build fails)
//Then I read this: https://github.com/gatsbyjs/gatsby/issues/2392
//The last post (jan 15) mentions, at LEAST 1 POST needs to have the Field Defined, and then graphql will shut up..
//I confirmed by adding a random *.md with bannerImage > that local builds and remote builds started working just fine
//If you see a similar error: At least 1 *.md must have the 'new' field defined!
export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        readMins
        bannerImage {
          childImageSharp {
            fluid(maxWidth: 1500, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

const smallTextColor = 'gray';  //lightslategray

const smallPStyle = {
    ...fontStyle,
    ...{
      color: smallTextColor,
      fontSize: subheaderFontSize - 14 + "px",
      
    },
  }